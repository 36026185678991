import { Currency, Measurement, typedObjectKeys } from '@/types/generics';
import { z } from 'zod';

export const ReservationStatuses = {
    RECEIVED: 'Recibida',
    RETURNED: 'Devuelta',
    CHANGE_OF_VEHICLE: 'Cambio de coche',
    DISCARDED_NO_REFUND: 'Cancelada sin devolución',
};
const [firstReservationStatusKey, ...restReservationStatusKey] = typedObjectKeys(ReservationStatuses);
export const ReservationStatus = z.enum([firstReservationStatusKey, ...restReservationStatusKey]);

export const ReservationMethods = {
    BANK_TRANSFER: 'Transferencia bancaria',
    ONLINE_PAYMENT: 'Stripe',
};
const [firstReservationMethodKey, ...restReservationMethodKey] = typedObjectKeys(ReservationMethods);
export const ReservationMethod = z.enum([firstReservationMethodKey, ...restReservationMethodKey]);

export const ReservationCancelReasons = {
    CERTIFICATION_KO: 'Certificación KO',
    FINAL_CLIENT_DOES_NOT_WANT: 'Cliente final no quiere',
    CAR_NOT_AVAILABLE: 'Coche No Disponible',
    PURCHASED_ELSEWHERE: 'Compra en otro sitio',
    FINANCING_DENIED: 'Financiación Denegada',
    OWNER_DOES_NOT_ANSWER: 'Propietario No Contesta',
    OWNER_ASKS_FOR_MORE_MONEY: 'Propietario pide más €',
    AD_ERROR: 'Error en anuncio',
    TRADE_IN_PRICE_DISAGREEMENT: 'Desacuerdo precio Trade In',
    FINAL_CLIENT_CAR_DEVOLUTION: 'Devolución coche',
    OWNER_DOES_NOT_WANT_SELL: 'Cliente (vendedor) no quiere vender',
    FINANCING_HUNDRED_PERCENT: 'Financiación 100%',
};
const [firstReservationCancelReasonKey, ...restReservationCancelReasonKeys] = typedObjectKeys(ReservationCancelReasons);
export const ReservationCancelReason = z.enum([firstReservationCancelReasonKey, ...restReservationCancelReasonKeys]);

export const DealReservationFormData = z.object({
    reservationDate: z.date(),
    amount: z.number(),
    currency: Currency,
    status: ReservationStatus,
    method: ReservationMethod,
    returnedDate: z.date(),
    discardedNoRefundDate: z.date(),
    cancelReason: ReservationCancelReason.optional().nullable(),
});

export const PostDealReservationRequest = z.object({
    reservationDate: z.string().datetime(),
    amount: z.number(),
    currency: Currency,
    status: ReservationStatus,
    method: ReservationMethod,
    returnedDate: z.string().datetime().nullable(),
    cancelReason: ReservationCancelReason.optional().nullable(),
});

export const DealReservationResponse = z.object({
    uuid: z.string(),
    reservationDate: z.string().datetime(),
    amount: Measurement,
    status: ReservationStatus,
    method: ReservationMethod,
    returnedDate: z.string().datetime().nullable(),
    discardedNoRefundDate: z.string().datetime().nullable(),
    cancelReason: ReservationCancelReason.optional().nullable(),
});

export const DealReservationResponseV2 = DealReservationResponse.extend({
    dealUuid: z.string(),
});

// Reservations

export const ReservationsList = z.object({
    max: z.number(),
    offset: z.number(),
    items: z.array(DealReservationResponseV2),
    totalItems: z.number(),
});

export const ReservationsFilters = z.object({
    licensePlate: z.string().nullable().optional(),
    statuses: z.array(ReservationStatus).optional(),
});

export type ReservationStatuses = z.infer<typeof ReservationStatus>;
export type ReservationMethods = z.infer<typeof ReservationMethod>;
export type DealReservationFormData = z.infer<typeof DealReservationFormData>;
export type PostDealReservationRequest = z.infer<typeof PostDealReservationRequest>;
export type DealReservationResponse = z.infer<typeof DealReservationResponse>;
export type DealReservationResponseV2 = z.infer<typeof DealReservationResponseV2>;

export type ReservationsList = z.infer<typeof ReservationsList>;
export type ReservationsFilters = z.infer<typeof ReservationsFilters>;
