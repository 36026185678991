import { Option, typedObjectKeys } from '@/types/generics';
import { z } from 'zod';
import { ReservationStatus } from './reservation';

export enum DealsSortingField {
    CREATED_AT = 'CREATED_AT',
    UPDATED_AT = 'UPDATED_AT',
    ID = 'ID',
    PRODUCT_SOURCE = 'PRODUCT_SOURCE',
    CATALOGUE_VEHICLE_LICENSE_PLATE = 'CATALOGUE_VEHICLE_LICENSE_PLATE',
    CATALOGUE_VEHICLE_MAKE = 'CATALOGUE_VEHICLE_MAKE',
    CATALOGUE_VEHICLE_MODEL = 'CATALOGUE_VEHICLE_MODEL',
    SALES_AGENT_NAME = 'SALES_AGENT_NAME',
    SITUATION = 'SITUATION',
    STAGE_NAME = 'STAGE_NAME',
    CLIENT_NAME = 'CLIENT_NAME',
    CLIENT_PHONE = 'CLIENT_PHONE',
}

const dealSortTypeEnum: readonly string[] = Object.keys(DealsSortingField);
const DealSortingType = z.enum([dealSortTypeEnum[0], ...dealSortTypeEnum]);

export const stages = {
    LEAD_CALLED: 'Lead Called',
    LEAD_CREATED: 'Lead Created',
    LEAD_PRE_QUOTED: 'Lead Pre-Quoted',
    LEAD_OPPORTUNITY: 'Lead Opportunity',
    LEAD_QUOTED: 'Lead Quoted',
    DEAL_CREATED: 'Deal Created',
    DEAL_QUALIFIED: 'Deal Qualified',
    DEAL_WON: 'Deal Won',
    CLIENT_CREATED: 'Client Created',
    CLIENT_CANCELED: 'Client Canceled',
};

export const stagesC2C = {
    LEAD_CREATED: 'Nuevo',
    LEAD_SENT_TO_BOT: 'Enviado a bot',
    LEAD_PENDING_CALL: 'Gestionado por Carlos',
    LEAD_MANAGED_WITHOUT_CALL: 'Gestionado sin teléfono',
    LEAD_CALLED: 'Contactado pero no informado',
    LEAD_PRE_QUOTED: 'Cliente informado pte presupuesto',
    LEAD_QUOTED: 'Presupuesto enviado',
    DEAL_CREATED: 'F-Tramitando',
    DEAL_WAITING: 'F-Esperando',
    DEAL_IN_ANALYSIS: 'F-En análisis',
    DEAL_PRE_APPROVED: 'F-Preaprobada',
    DEAL_APPROVED: 'F-Aprobada',
    DEAL_QUALIFIED: 'F-Cobrada',
    DEAL_WON: 'Vendido',
    DEAL_LOST: 'Descartado',
};

export type StagesC2CKeys = keyof typeof stagesC2C;

export const lostReasonsPaid = {
    REJECTED_BY_BANK: 'Financiación rechazada',
    REJECTED_BY_CLIENT: 'Cliente no acepta financiación',
};

export const lostReasonsRent = {
    KO_CERTIFICATION: 'Certificación KO',
    HIGH_INSTALLMENT: 'Cuota muy alta',
    NO_CAR: 'No tiene coche o vehículo no apto',
    NO_CHANGE_OF_OWNERSHIP: 'No quiere cambio de titularidad',
    NO_AMORTIZING_INSTALLMENT: 'Cuota no amortiza',
};

export const lostReasonsC2C = {
    NO_ANSWER: 'Ilocalizable',
    CUSTOMER_STOPS_ANSWERING: 'Cliente deja de contestar',
    CLIENT_NEED_TO_SEE_CAR: 'No compra sin ver el coche',
    POSTPONED: 'Decide dejarlo para más adelante',
    NON_FINANCIABLE: 'Perfil no financiable',
    WRONG_CLIENT_DATA: 'Datos cliente erróneos',
    DISAGREE_PRICE_TRADE_IN: 'Desacuerdo precio trade in',
    DUPLICATED_LEAD: 'Lead duplicado',
    CAR_RESERVED: 'Coche reservado',
    ALREADY_PURCHASED: 'Ya ha comprado en otro sitio',
    CHANGE_OF_VEHICLE: 'Cambio de coche Clidrive',
    PENDING_SALE_HIS_CAR: 'Pendiente de venta de su coche para comprar',
    FINDS_EXPENSIVE: 'Le parece caro',
    DISTRUST_OF_ONLINE_SALE: 'Desconfianza venta online',
    AD_ERROR: 'Error en anuncio',
    OUT_OF_STOCK: 'Sin stock disponible',
    REJECTED_BY_BANK: 'Financiación denegada',
    UNPUBLISHED_CAR: 'Coche despublicado',
    RESERVATION_REFUND: 'Devolución reserva',

    TEST: 'Test interno',
};

export const oldLostReasonsC2C = {
    CAR_SOLD_BY_OWNER: 'Coche vendido por el dueño',
    NOT_INTERESTED: 'No interesado',
    WANT_CASH_SALE: 'Quiere venta al contado',
};

export const lostReasonsCommon = {
    COMPETITOR: 'Competidor',
    VEHICLE_NOT_ELIGIBLE: 'Vehículo no elegible',
    CLIENT_NOT_ELIGIBLE: 'Cliente no elegible',
    NO_DOCUMENTATION: 'No aporta documentación imprescindible',
    NO_ANSWER: 'Ilocalizable - NC',
    NOT_INTERESTED: 'No interesado - NC',
    REJECTED_BY_PSD2: 'PSD2 rechazada',
    POTENTIAL_FRAUD: 'Posible Fraude',
    WRONG_CLIENT_DATA: 'Datos Cliente erróneos',
    DISTRUST_IN_OUR_BUSINESS: 'Desconfianza en nuestro negocio',
    NOT_SATISFIED_APPRAISAL: 'No conforme con la tasación',
    CAR_APPRAISAL_FOR_SALE: 'Quería tasar su coche para venderlo',
};

export const allC2CLostReasons = {
    ...lostReasonsC2C,
    ...oldLostReasonsC2C,
};

export const lostReasons = {
    ...lostReasonsPaid,
    ...lostReasonsRent,
    ...lostReasonsC2C,
    ...lostReasonsCommon,
    ...oldLostReasonsC2C,
};

export const eligibleFinancierRejections = {
    car_stolen: 'Coche con alerta robado',
    maximun_years_car_exceeded: 'Nº años máximo excedido',
    maximun_km_car_exceeded: 'Nº kms máximo excedido',
    owner_changed_recent_too_recent: 'Cambio de titular muy reciente (<25 días)',
    client_monthly_income_too_low: 'Ingreso mensual bajo',
    client_annual_income_too_low: 'Ingreso anual bajo',
    client_seniority_date_too_low: 'Insuficiente antigüedad en empresa',
    client_professional_status_unemployed: 'Estado desempleado',
    client_not_legal_age: 'Menor de edad',
    client_too_old: 'Límite de edad superado',
    vehicle_data_not_found: 'No se encuentra datos del coche',
    vehicle_year_car_not_found: 'Año del coche no encontrado',
    client_ask_amount_too_low: 'PVP Ask muy bajo',
    ask_amount_too_low: 'PVP Ask muy bajo',
    client_unprofitable_client_by_cpa: 'Cliente no rentable por CPA',
    vehicle_stolen: 'Vehículo robado',
    vehicle_maximun_years_exceeded: 'Años máximos del vehículo excedidos',
    vehicle_maximun_km_exceeded: 'Kms máximos del vehículo excedidos',
    vehicle_owner_changed_too_recent: 'Cambio de titular muy reciente',
    vehicle_appraisal_less_than_3000: 'Tasación inferior a 3000€',
    vehicle_appraisal_less_than_4500: 'Tasación inferior a 4500€',
    client_employment_object_not_found: 'No se encuentra el empleo del cliente',
    psd2_approved_with_micro: 'No elegible por resultado de agregación bancaria',
    client_with_asnef_amount: 'Cliente supera ASNEF máximo',
    vehicle_missing_information: 'No se ha encontrado información del vehículo',
    vehicle_is_motorcycle: 'El vehículo es una motocicleta',
    vehicle_appraisal_less_than_10000: 'Tasación inferior a 10.000€',
};

export const situations = {
    OPEN: 'Open',
    WON: 'Won',
    LOST: 'Lost',
    DELETED: 'Deleted',
};

export const situationsC2C = {
    OPEN: 'En proceso',
    WON: 'Vendido',
    LOST: 'Descartado',
};

export const tiers = {
    RENT: 'RENT',
    FINANCED: 'FINANCED',
    PAID: 'PAID',
    C2C: 'C2C',
    DEALER: 'DEALER',
};

export const starredFilterOptions = {
    true: 'Marcado',
    false: 'Desmarcado',
};

export const vatRates = {
    REBU: 'REBU',
    IVA: 'IVA',
};
export const productSourceMap = {
    CREDIT_CAR: 'CreditCar',
    CLIDRIVE_SAVE: 'Clidrive Save',
    CLIDRIVE_RENT: 'Clidrive Rent',
    CLIDRIVE_PAID: 'Clidrive Paid',
    CLIDRIVE_FINANCED: 'Clidrive Financed',
    C2C_COCHESNET: 'Coches.net',
    C2C_AUTOCASION: 'Autocasión',
    C2C_MILANUNCIOS: 'Milanuncios',
    C2C_WALLAPOP: 'Wallapop',
    C2C_CLIDRIVECOM: 'Clidrive.com',
    // C2C_COCHESCOM: 'Coches.com',
};

const [firstVatRateKey, ...restVatRateKeys] = typedObjectKeys(vatRates);
const stagesEnum: readonly string[] = Object.keys(stages) as (keyof typeof stages)[];
const stagesC2CEnum: readonly string[] = Object.keys(stagesC2C) as (keyof typeof stagesC2C)[];
const situationsEnum: readonly string[] = Object.keys(situations) as (keyof typeof situations)[];
const situationsC2CEnum: readonly string[] = Object.keys(situationsC2C) as (keyof typeof situationsC2C)[];
const tiersEnum: readonly string[] = Object.keys(tiers) as (keyof typeof tiers)[];
const lostReasonsEnum: readonly string[] = Object.keys(lostReasons) as (keyof typeof lostReasons)[];
const eligibleFinancierRejectionsEnum: readonly string[] = Object.keys(
    eligibleFinancierRejections,
) as (keyof typeof eligibleFinancierRejections)[];
const productSourceEnum: readonly string[] = Object.keys(productSourceMap) as (keyof typeof productSourceMap)[];

export const Tier = z.enum([tiersEnum[0], ...tiersEnum]);
export const Situation = z.enum([situationsEnum[0], ...situationsEnum]);
export const SituationC2C = z.enum([situationsC2CEnum[0], ...situationsC2CEnum]);
export const StageC2C = z.enum([stagesC2CEnum[0], ...stagesC2CEnum]);
export const Stage = z.enum([stagesEnum[0], ...stagesEnum]).or(StageC2C);
export const LostReason = z.enum([lostReasonsEnum[0], ...lostReasonsEnum]);
export const EligibleFinancierRejection = z.enum([
    eligibleFinancierRejectionsEnum[0],
    ...eligibleFinancierRejectionsEnum,
]);

export const VatRate = z.enum([firstVatRateKey, ...restVatRateKeys]);

export const ProductSource = z.enum([productSourceEnum[0], ...productSourceEnum]);

const DealStage = z.object({
    uuid: z.string(),
    stage: Stage,
});
const DealSalesAgent = z.object({
    name: z.string(),
});

const Vehicle = z.object({
    uuid: z.string().optional(),
    licensePlate: z.string().nullable().optional(),
});
const CatalogueVehicle = Vehicle.extend({
    makeName: z.string().nullable(),
    modelName: z.string().nullable(),
});
const Valuation = z.object({
    receivedAmount: z.number().optional(),
});

export const Agent = z.object({
    uuid: z.string().uuid().optional(),
    name: z.string(),
});

export const DealFormQueryFilters = z.object({
    situation: Situation.or(z.literal('')).optional(),
    licensePlate: z.string().optional(),
    stage: Stage.or(z.literal('')).optional(),
    dealIds: z.string().optional(),
    opsDealId: z.string().optional(),
    selectedFinancer: z.string().optional(),
    tier: Tier.or(z.literal('')).optional(),
    receivedAmount: z.string().optional(),
    salesAgentUuid: z.string().optional(),
    clientEmail: z.string().optional(),
    clientPhone: z.string().optional(),
    includeLastNote: z.boolean().optional(),
    reservationStatuses: ReservationStatus.optional(),
    isStarred: z.boolean().optional(),
    byOriginSources: z.array(z.string()).optional(),
    firstWonTimeBetween: z.array(z.date()).optional(),
    firstWonTimeFrom: z.string().datetime().optional(),
    firstWonTimeTo: z.string().datetime().optional(),
    createdAtBetween: z.array(z.date()).optional(),
    createdAtFrom: z.string().datetime().optional(),
    createdAtTo: z.string().datetime().optional(),
    clientUuid: z.string().optional(),
    lostReasons: z.array(Option).optional(),
    dealUuid: z.string().optional(),
    vatRate: VatRate.optional(),
    productSource: ProductSource.optional(),
});

export const DealFormFilters = DealFormQueryFilters.extend({
    salesAgent: z
        .object({
            label: z.string(),
            value: z.string(),
        })
        .nullable()
        .optional(),
    sortField: DealSortingType.optional(),
    sortDirection: DealSortingType.optional(),
});

export const DealFilters = DealFormFilters.extend({
    max: z.number().optional(),
    offset: z.number().optional(),
    sortField: DealSortingType.optional(),
    sortDirection: DealSortingType.optional(),
});

export const Deal = z.object({
    id: z.number(),
    uuid: z.string(),
    opsDealId: z.string().optional(),
    client: z.object({
        uuid: z.string(),
        name: z.string(),
        lastName: z.string(),
        email: z.string().nullable(),
        phone: z.string().nullable(),
    }),
    vehicle: Vehicle.optional(),
    catalogueVehicle: CatalogueVehicle.optional(),
    situation: Situation.optional(),
    tier: Tier,
    stage: DealStage.optional(),
    salesAgent: DealSalesAgent.optional().nullable(),
    valuation: Valuation.optional(),
    createdAt: z.string().datetime(),
    lastNote: z
        .object({
            content: z.string(),
            createdAt: z.string(),
        })
        .optional(),
    isStarred: z.boolean(),
    lostReason: z.string().optional().nullable(),
    firstWonTime: z.string().datetime().optional(),
    productSource: z.string().optional(),
});

export const GetDealListResponse = z.object({
    max: z.number(),
    offset: z.number(),
    items: z.array(Deal).default([]),
    totalItems: z.number(),
});

export const DealTableRow = z.object({
    id: z.number(),
    uuid: z.string(),
    opsDealId: z.string().optional(),
    client: z.object({
        uuid: z.string(),
        name: z.string(),
        lastName: z.string(),
        email: z.string().nullable(),
        phone: z.string().nullable(),
    }),
    makeName: z.string().nullable().optional(),
    modelName: z.string().nullable().optional(),
    createdAt: z.string().datetime().optional(),
    tier: Tier.optional(),
    licensePlate: z.string().nullable().optional(),
    situation: Situation.optional(),
    stage: Stage.optional(),
    receivedAmount: z.number().optional(),
    lastNote: z
        .object({
            content: z.string(),
            createdAt: z.string(),
        })
        .optional(),
    isStarred: z.boolean(),
    lostReason: z.string().nullable().optional(),
    salesAgent: DealSalesAgent.optional().nullable(),
    firstWonTime: z.string().datetime().optional(),
    productSource: z.string().optional(),
});

export const DealBasicInfo = z.object({
    id: z.number(),
    uuid: z.string(),
    opsDealId: z.string().nullable().optional(),
    salesAgent: Agent.nullable().optional(),
    financeAgent: Agent.nullable().optional(),
    certifierAgent: Agent.nullable().optional(),
    tier: Tier.nullable().optional(),
    selectedFinancier: z.string().nullable().optional(),
    eligibleFinanciers: z.array(z.string()).nullable().optional(),
    eligibleFinancierRejections: z.array(z.string()).nullable().optional(),
    noEligibleFinancierReasons: z.record(z.array(z.string())).or(z.object({})),
    situation: Situation.nullable().optional(),
    lostReason: LostReason.nullable().optional().or(z.string().nullable().optional()),
    vehicle: Vehicle.nullable().optional(),
    stage: DealStage.nullable().optional(),
    wonTime: z.string().datetime().optional().nullable(),
    lostTime: z.string().datetime().optional().nullable(),
    createdAt: z.string().datetime().optional().nullable(),
    isStarred: z.boolean(),
    acquisitionData: z.record(z.string().nullable()).nullable().optional(),
});

export const PutDealAssignedAgentRequest = z.object({
    financeAgentExternalId: z.string().nullable().optional(),
    certifierAgentExternalId: z.string().nullable().optional(),
    salesAgentExternalId: z.string().nullable().optional(),
    processorAgentExternalId: z.string().nullable().optional(),
});

export const PutDealAssignedAgentResponse = PutDealAssignedAgentRequest;

export const DealListViewSkeleton = z.object({
    items: z.array(DealTableRow),
    pages: z.number(),
    offset: z.number(),
    totalItems: z.number(),
});

export const PutDealSituationRequest = z.object({
    situation: Situation,
    lostReason: LostReason.optional().nullable(),
});

export const GetDealBasicInfo = DealBasicInfo;
export const PutDealSituationResponse = PutDealSituationRequest;

export const GetDealDocumentationLinkResponse = z.object({
    link: z.string().nullable(),
});

export const PatchDealRequest = z.object({
    firstWonTime: z.string().optional(),
    isStarred: z.boolean().optional(),
});

export const PutDealVehicleLicensePlateRequest = z.object({
    licensePlate: z.string().optional(),
});

export const PutDealDocumentationLinkRequest = GetDealDocumentationLinkResponse;

export type Deal = z.infer<typeof Deal>;
export type DealTableRow = z.infer<typeof DealTableRow>;
export type GetDealListResponse = z.infer<typeof GetDealListResponse>;
export type DealListViewSkeleton = z.infer<typeof DealListViewSkeleton>;
export type Situation = z.infer<typeof Situation>;
export type SituationC2C = z.infer<typeof SituationC2C>;
export type Vehicle = z.infer<typeof Vehicle>;
export type Valuation = z.infer<typeof Valuation>;
export type DealFormFilters = z.infer<typeof DealFormFilters>;
export type Agent = z.infer<typeof Agent>;
export type DealFormQueryFilters = z.infer<typeof DealFormQueryFilters>;
export type DealFilters = z.infer<typeof DealFilters>;
export type DealBasicInfo = z.infer<typeof DealBasicInfo>;
export type Stage = z.infer<typeof Stage>;
export type StageC2C = z.infer<typeof StageC2C>;
export type Tier = z.infer<typeof Tier>;
export type LostReason = z.infer<typeof LostReason>;
export type GetDealBasicInfo = z.infer<typeof GetDealBasicInfo>;
export type PutDealSituationRequest = z.infer<typeof PutDealSituationRequest>;
export type PutDealSituationResponse = z.infer<typeof PutDealSituationResponse>;
export type PutDealAssignedAgentRequest = z.infer<typeof PutDealAssignedAgentRequest>;
export type PutDealAssignedAgentResponse = z.infer<typeof PutDealAssignedAgentResponse>;
export type GetDealDocumentationLinkResponse = z.infer<typeof GetDealDocumentationLinkResponse>;
export type PutDealDocumentationLinkRequest = z.infer<typeof PutDealDocumentationLinkRequest>;
export type PatchDealRequest = z.infer<typeof PatchDealRequest>;
export type PutDealVehicleLicensePlateRequest = z.infer<typeof PutDealVehicleLicensePlateRequest>;
export type LostReasonC2CKey = keyof typeof lostReasonsC2C;
export type LostReasonC2CValue = (typeof lostReasonsC2C)[LostReasonC2CKey];
